// Set up for prerender
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import ReactRailsUJS from 'react_ujs';

// Polyfill for classes see https://babeljs.io/docs/usage/polyfill/
// import '@babel/polyfill';

// import BetterNav from '../components/better-nav';

import Timetable from '../components/weekly_prog/index.jsx';
import FacilityFinder from '../components/facility-finder';
import FitnessBest from '../components/fitness-best';

// // Set up for global scope - PLEASE DO NOT TOUCH
const app = (window.app = global.app = {});

// // <<<<<<<<<<<<< Please add all components to APP >>>>>>>>>>>>

// //  Components
// // app.BetterNav = BetterNav;
app.FacilityFinder = FacilityFinder;
app.FitnessBest = FitnessBest;
app.Timetable = Timetable;

// Initialise ReactUJS
// ReactRailsUJS.mountComponents();
