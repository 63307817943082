import React, { Component } from 'react';

import Loader from '../loader';

export default class FitnessLoader extends Component {
  state = {
    loaded: false,
    FitnessBest: null,
    error: false,
  };

  componentDidMount() {
    import(/* webpackChunkName: "fitness-best-component" */ /* webpackMode: "lazy" */ './fitness_best')
      .then(({ default: FitnessBest }) => {
        this.setState({ FitnessBest, loaded: true });
      })
      .catch(e => {
        this.setState({ error: true, loaded: true });
      });
  }

  render() {
    const { loaded, FitnessBest, error } = this.state;
    if (!loaded) {
      return (
        <div className="loader">
          <Loader fill="#999" />
          <p className="center loader__text">Loading</p>
        </div>
      );
    }

    if (error) {
      return (
        <p className="center epsilon">
          We are sorry, there seems to be an issue loading the finder. If the issues persists please
          <a href="/contactus"> contact us</a>
        </p>
      );
    }

    return <FitnessBest {...this.props} />;
  }
}
