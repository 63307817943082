import React, { Component } from 'react';

import Loader from '../loader';

export default class FacilityLoader extends Component {
  state = {
    loaded: false,
    FacilityFinder: null,
    error: false,
  };

  componentDidMount() {
    import(/* webpackChunkName: "facility-finder-component" */ /* webpackMode: "lazy" */ './facility_finder')
      .then(({ default: FacilityFinder }) => {
        this.setState({ FacilityFinder, loaded: true });
      })
      .catch(e => {
        this.setState({ error: true, loaded: true });
      });
  }

  render() {
    const { loaded, FacilityFinder, error } = this.state;
    if (!loaded) {
      return (
        <div className="loader">
          <Loader fill="#999" />
          <p className="center loader__text">Loading</p>
        </div>
      );
    }

    if (error) {
      return (
        <p className="center epsilon">
          We are sorry, there seems to be an issue loading the finder. If the issues persists please
          <a href="/contactus"> contact us</a>
        </p>
      );
    }

    return <FacilityFinder {...this.props} />;
  }
}
